@import 'variables';
@import 'loader';

.navbar{
  background-color: $nav-bg;
  border-radius: 0;
  height: 355px;
  margin-bottom: 0;
  .navbar-header {
    margin: auto;
    width: 300px;
    padding: 40px 0;
    float: none;
    .navbar-brand {
      height: 90px;
      img {
        width: 60px;
        height: 60px;
      }
    }
    .navbar-brand-text {
      float: left;
      padding: 15px 0;

      h4 {
        margin-bottom: 0;
        color: #fff;
        font-weight: bold;
      }

      span {
        color: $btn-primary-bg-color;
        font-weight: bold;
      }
    }
  }
}

.tnc{
  display: flex;
}

/*Form css*/
.form-control:focus {
  border-color: rgba(180, 228, 15, 0.95);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(180, 228, 15, 0.6);
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:hover {
  background-color: $btn-primary-bg-color !important;
  border: none !important;
  color: $text !important;
  outline: none !important;
}

.btn+.btn {
  margin-bottom: 0;
  margin-left: 5px;
}

@media only screen and (max-device-width : 600px) {
  .navbar {
    height: 300px;
  .navbar-header {
    padding: 20px 0;
    .navbar-brand-text {
      h4 {
        font-size: 14px;
        margin-top: 13px;
      }
    }
  }
  }
  .panel-default {
    width: 100% !important;
    &.panel-otp {
      margin-top: -100px !important;
    }
    .panel-body {
      padding: 0 15px 25px 15px !important;

      .otp-timer {
        margin-left: 25px !important;
      }

      .otp-resend {
        margin-left: 0 !important;
      }

      .radio-inline {
        margin-right: 5px;
      }
      .radio-inline + .radio-inline {
        margin-left: 0 !important;
      }

      .submit-button {
        margin-top: 15px !important;
      }

      .panel-status {
        padding-top: 25px !important;
      }
    }
  }
  .heading {
    font-size: 30px !important;
    margin: 20px 0 !important;
  }
}

.error {
  color: $error-text !important;
  &:before {
    content: "\f06a";
    margin-right: 5px;
    font-family: FontAwesome;
  }
}

.panel {
  &.panel-default {
    position: relative;
    width: 565px;
    max-height: 390px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    margin: -150px auto 20px auto;

    .token-validity-timer {
      position: absolute;
      right: 8px;
      top: 6px;
      padding: 4px 6px;
      border-radius: 4px;
      font-weight: 800;
      &.red {
        background: red;
      }
    }
  }

  .panel-status {
    text-align: center;
    padding-top: 50px;
    img {
      width: 80px;
      height: 80px;
    }
    h2 {
      font-size: 35px;
      font-weight: bold;
      color: #000000;
    }
    p {
      color: #333333;
      text-align: center;
    }
    .status-info {
      color: #000000;
      font-size: 14px;
    }
  }

  .panel-otp {
    max-height: 300px;
  }

  &.panel-payment {
    max-height: 600px;
    margin-top: -180px;
  }
  .payment-info {
    color: $error-text;
    text-align: center;
    font-size: 14px;
    margin-top: 15px;
  }

  .panel-body {
    padding: 0 100px 50px 100px;
    .otp-label {
      display: flex;
      justify-content: space-between;
    }
  }

  .otp-resend {
    cursor: pointer;
    color: #00904b;
    margin-left: 15px;
  }

  .otp-timer {
    margin-left: 10px;
    color: #00904b;
  }

  .disable {
    cursor: not-allowed;
    opacity: 0.5;
    color: #ccc;
  }

  .heading {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 35px;
    font-weight: bold;
    color: $nav-bg;
  }

  .info + .heading {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .submit-button {
    text-align: center;
    margin-top: 20px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: #000000;
    padding: 10px;

    p {
      margin: 0;
    }
  }

  .success-info {
    background-color: $btn-primary-bg-color;
  }

  .error-info {
    background-color: rgba(255, 0, 0, 0.12);
  }
}

//404 page styles

.not-found-section {
  .bg-image {
    background-image: url("../images/404.svg");
  }
  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  strong {
    font-weight: inherit;
  }

  strong {
    font-weight: bolder;
  }

  code {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  dfn {
    font-style: italic;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  button,
  input {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  input {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  legend {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }

  [hidden] {
    display: none;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  p {
    margin: 0;
  }

  button {
    background: transparent;
    padding: 0;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  *,
  *::before,
  *::after {
    border-width: 0;
    border-style: solid;
    border-color: #dae1e7;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    border-radius: 0;
  }

  button,
  input {
    font-family: inherit;
  }

  button,
  [role=button] {
    cursor: pointer;
  }

  .bg-transparent {
    background-color: transparent;
  }

  .bg-white {
    background-color: #fff;
  }

  .bg-teal-light {
    background-color: #64d5ca;
  }

  .bg-blue-dark {
    background-color: #2779bd;
  }

  .bg-indigo-light {
    background-color: #7886d7;
  }

  .bg-purple-light {
    background-color: #a779e9;
  }

  .bg-no-repeat {
    background-repeat: no-repeat;
  }

  .bg-cover {
    background-size: cover;
  }

  .border-grey-light {
    border-color: #dae1e7;
  }

  .hover\:border-grey:hover {
    border-color: #b8c2cc;
  }

  .rounded-lg {
    border-radius: .5rem;
  }

  .border-2 {
    border-width: 2px;
  }

  .hidden {
    display: none;
  }

  .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .font-sans {
    font-family: Nunito, sans-serif;
  }

  .font-light {
    font-weight: 400;
  }

  .font-bold {
    font-weight: 700;
  }

  .font-black {
    font-weight: 900;
  }

  .h-1 {
    height: .25rem;
  }

  .leading-normal {
    line-height: 1.5;
  }

  .m-8 {
    margin: 2rem;
  }

  .my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .mb-8 {
    margin-bottom: 2rem;
  }

  .max-w-sm {
    max-width: 30rem;
  }

  .min-h-screen {
    min-height: 100vh;
  }

  .py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .pb-full {
    padding-bottom: 100%;
  }

  .absolute {
    position: absolute;
  }

  .relative {
    position: relative;
  }

  .pin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .text-black {
    color: #22292f;
  }

  .text-grey-darkest {
    color: #3d4852;
  }

  .text-grey-darker {
    color: #606f7b;
  }

  .text-2xl {
    font-size: 1.5rem;
  }

  .text-5xl {
    font-size: 3rem;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .tracking-wide {
    letter-spacing: .05em;
  }

  .w-16 {
    width: 4rem;
  }

  .w-full {
    width: 100%;
  }

  @media (min-width: 768px) {
    .md\:bg-left {
      background-position: left;
    }

    .md\:bg-right {
      background-position: right;
    }

    .md\:flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .md\:my-6 {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .md\:min-h-screen {
      min-height: 100vh;
    }

    .md\:pb-0 {
      padding-bottom: 0;
    }

    .md\:text-3xl {
      font-size: 26px;
    }

    .md\:text-15xl {
      font-size: 9rem;
    }

    .md\:w-1\/2 {
      width: 50%;
    }
  }

  @media (min-width: 992px) {
    .lg\:bg-center {
      background-position: center;
    }
  }
}
