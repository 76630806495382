/* #PRELOADER  ============================================== */
/* This only works with JavaScript,
if it's not present, don't show loader */
#loader-overflow{
  display:block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fefefe;
  z-index: 99999;
}
.loader-cont{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#loader3 {
  will-change: transform;
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  background-image: url(../images/favicon-kvb.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #b6b6b7;
  border-radius: 50%;
  -webkit-animation: scaleout 1.1s infinite ease-in-out;
  animation: scaleout 1.1s infinite ease-in-out;

  text-indent: -99999px;
  z-index: 999991;
}
@-webkit-keyframes scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}
@keyframes scaleout {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.0);
    -moz-transform: scale(1.0);
    -ms-transform: scale(1.0);
    -o-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
